import { RequestGateway } from "@/store/RequestGateway";
import StorageUtil from "@/utils/StorageUtil";
import { showToast } from "@/constant/toast";

const getDefaultState = () => ({
    usersList: [],
    userTotalRow: 0,
    userlistloadingflag: true,
    userCreatedFlag: false,
    userDeletedFlag: false,
    userUpdatedFlag: false,
    userPasswordResetFlag: false,
});

export default {
    state: getDefaultState(),
    getters: {
        getUsersList: (state) => state.usersList,
        getUsers: (state) => state.users,
        getUserTotalRow: (state) => state.userTotalRow,
        getuserlistLoadingFlag: (state) => state.userListLoadingFlag,
        getUserCreatedFlag: (state) => state.userCreatedFlag,
        getUserDeletedFlag: (state) => state.userDeletedFlag,
        getUserUpdatedFlag: (state) => state.userUpdatedFlag,
        getUserPasswordResetFlag: (state) => state.userPasswordResetFlag,
    },
    mutations: {
        SET_USERS_LIST(state, data) {
            state.usersList = data.data;
            state.userTotalRow = data.totalCount;
        },
        SET_USERS(state, data) {
            state.users = data;
        },
        SET_USER_CREATED_FLAG(state, flag) {
            state.userCreatedFlag = flag;
        },
        SET_USER_DELETED_FLAG(state, flag) {
            state.userDeletedFlag = flag;
        },
        SET_USER_UPDATED_FLAG(state, flag) {
            state.userUpdatedFlag = flag;
        },
        SET_USER_PASSWORD_RESET_FLAG(state, flag) {
            state.userPasswordResetFlag = flag;
        },
    },
    actions: {
        async fetchUsersList(
            { commit },
            { page = 1, perPage = 10, search = "", showLoading = false } = {}
        ) {
            if (showLoading) {
                commit("CHANGE_LOADING_STATUS", true);
            }

            const feature = "inhouse";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/users?page=${page}&perPage=${perPage}&search=${encodeURIComponent(
                search
            )}`;

            try {
                const response = await RequestGateway.get(feature, url, {});
                if (response.success === true) {
                    const users = response?.data || [];
                    const totalCount = response?.totalCount || 0;

                    commit("SET_USERS_LIST", { data: users, totalCount });

                    return response;
                }
            } catch (error) {
                commit("SET_USER_CREATED_FLAG", false);
                throw error;
            }
        },
        async fetchUser({ commit }, userId) {
            const feature = "inhouse";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/users/${userId}`;

            try {
                const response = await RequestGateway.get(feature, url, {});
                return response;
            } catch (error) {
                return error;
            }
        },
        async createUser({ commit }, payload) {
            const feature = "inhouse";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/users`;

            try {
                const response = await RequestGateway.post(
                    feature,
                    url,
                    payload,
                    {}
                );
                if (response.success === true) {
                    showToast(
                        "success",
                        "Success",
                        "User Created successfully"
                    );
                    commit("SET_USER_CREATED_FLAG", true);
                }
                return response;
            } catch (error) {
                commit("SET_USER_CREATED_FLAG", false);
                return error;
            }
        },
        async updateUser({ commit }, { payload, userId }) {
            const feature = "inhouse";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/users/${userId}`;
            try {
                const response = await RequestGateway.put(
                    feature,
                    url,
                    payload,
                    {}
                );
                if (response.success === true) {
                    showToast(
                        "success",
                        "Success",
                        "User Updated successfully"
                    );
                    commit("SET_USER_UPDATED_FLAG", true);
                } else {
                    commit("SET_USER_UPDATED_FLAG", false);
                    showToast("error", "Error", "Failed to update user");
                }
                return response;
            } catch (error) {
                commit("SET_USER_UPDATED_FLAG", false);
                return error;
            }
        },
        async deleteUser({ commit }, userId) {
            const feature = "inhouse";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/users/${userId}`;

            try {
                const response = await RequestGateway.delete(feature, url, {});
                if (response.success === true) {
                    showToast(
                        "success",
                        "Success",
                        "User deleted successfully"
                    );
                    commit("SET_USER_DELETED_FLAG", true);
                } else {
                    commit("SET_USER_DELETED_FLAG", false); // Set flag to false on failure
                    showToast("error", "Error", "Failed to delete user");
                }
                return response;
            } catch (error) {
                commit("SET_USER_DELETED_FLAG", false);
                return error;
            }
        },
        async updateUsersPassword({ commit }, { payload, userId }) {
            const feature = "inhouse";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/users/${userId}/change-password`;

            try {
                const response = await RequestGateway.post(
                    feature,
                    url,
                    payload,
                    {}
                );
                if (response.success === true) {
                    commit("SET_USER_PASSWORD_RESET_FLAG", true);
                } else {
                    commit("SET_USER_PASSWORD_RESET_FLAG", false);
                }
                return response;
            } catch (error) {
                commit("SET_USER_PASSWORD_RESET_FLAG", false);
                return error;
            }
        },
    },
};
