import { RequestGateway } from "@/store/RequestGateway";
import StorageUtil from "@/utils/StorageUtil";
import { showToast } from "@/constant/toast";

const getDefaultState = () => ({
    permissions: [],
    rolesList: [],
});

export default {
    state: getDefaultState(),
    getters: {
        getPermissions: (state) => state.permissions,
        getRolesList: (state) => state.rolesList,
    },
    mutations: {
        GET_PERMISSIONS(state, data) {
            state.permissions = data;
        },
        GET_ROLES_LIST(state, data) {
            state.rolesList = data;
        },
    },
    actions: {
        async fetchPermissions({ commit }) {
            const feature = "inhouse";
            const url = `/permissions`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
            };

            try {
                const response = await RequestGateway.get(
                    feature,
                    url,
                    options
                );
                if (response && response.data.length) {
                    commit("GET_PERMISSIONS", response.data);
                    return response.data;
                } else {
                    return [];
                }
            } catch (error) {
                return error;
            }
        },

        async fetchPermissionfromRole({ commit }, roleId) {
            const feature = "inhouse";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/roles/${roleId}`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
            };

            try {
                const response = await RequestGateway.get(
                    feature,
                    url,
                    options
                );
                if (response && response.data) {
                    return response.data;
                } else {
                    return [];
                }
            } catch (error) {
                return error;
            }
        },

        async fetchRolesList({ commit }) {
            const feature = "inhouse";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/roles?perPage=200`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
            };

            commit("CHANGE_LOADING_STATUS", true);
            try {
                const response = await RequestGateway.get(
                    feature,
                    url,
                    options
                );
                if (response && response.data && response.data.length) {
                    commit("GET_ROLES_LIST", response.data);
                    return response;
                } else {
                    return [];
                }
            } catch (error) {
                return error;
            } finally {
                commit("CHANGE_LOADING_STATUS", false);
            }
        },

        async createRole({ commit }, payload) {
            const feature = "inhouse";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/roles`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
                data: payload,
            };

            try {
                const response = await RequestGateway.post(
                    feature,
                    url,
                    payload,
                    options
                );
                return response;
            } catch (error) {
                return error;
            }
        },

        async updateRole({ commit }, { payload, roleId }) {
            const feature = "inhouse";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/roles/${roleId}`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
                data: payload,
            };

            try {
                const response = await RequestGateway.put(
                    feature,
                    url,
                    payload,
                    options
                );
                return response;
            } catch (error) {
                return error;
            }
        },

        async deleteRole({ commit }, roleId) {
            const feature = "inhouse";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/roles/${roleId}`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
            };

            try {
                const response = await RequestGateway.delete(
                    feature,
                    url,
                    options
                );
                return response;
            } catch (error) {
                return error;
            }
        },
    },
};
