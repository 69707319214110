import StorageUtil from "@/utils/StorageUtil";
import { createRouter, createWebHistory } from "vue-router";
import ROUTES from "@/constant/appRoutes.js";

function guard(to, from, next) {
    const accessToken = StorageUtil.getSessionData("accessToken");
    if (
        accessToken &&
        accessToken != "" &&
        accessToken != undefined &&
        accessToken != null
    ) {
        next(); // Proceed to dashboard
    } else {
        next({ name: "Login" }); // Redirect to login if no access token
    }
}

const routes = [
    {
        path: "/",
        name: "Login",
        component: () => import("@/views/auth/login/index.vue"),
    },
    {
        path: "/forgot-password",
        name: "forgot-password",
        component: () => import("@/views/auth/forgot-password/index.vue"),
    },
    {
        path: "/app",
        name: "Layout",
        redirect: "/app/dashboard",
        component: () => import("@/Layout/index.vue"),
        beforeEnter: guard,
        children: [
            {
                path: "dashboard",
                name: "dashboard",
                component: () => import("@/views/dashboard/index.vue"),
                beforeEnter: guard,
                meta: {
                    hide: true,
                },
            },
            {
                path: "news",
                name: "news",
                component: () => import("@/views/news/index.vue"),
                meta: {
                    hide: true,
                },
            },
            {
                path: "threat-videos",
                name: "threat-videos",
                component: () => import("@/views/threat-videos/index.vue"),
                meta: {
                    hide: true,
                },
            },
            {
                path: "media-library",
                name: "media-library",
                component: () => import("@/views/media-library/index.vue"),
                meta: {
                    hide: true,
                },
            },
            {
                path: "media-library/upload",
                name: "upload-media",
                component: () =>
                    import("@/views/media-library/upload/index.vue"),
                props: true,
                meta: {
                    hide: true,
                },
            },
            {
                path: "reports",
                name: "reports",
                component: () => import("@/views/reports/index.vue"),
                meta: {
                    hide: true,
                },
            },
            {
                path: "users",
                name: "users",
                component: () => import("@/views/users/index.vue"),
                meta: {
                    hide: true,
                },
            },
            {
                path: "users/create",
                name: "create-user",
                component: () => import("@/views/users/create/index.vue"),
                props: true,
                meta: {
                    hide: true,
                },
            },
            {
                path: "users/edit/:id",
                name: "edit-user",
                component: () => import("@/views/users/edit/index.vue"),
                props: true,
                meta: {
                    hide: true,
                },
            },
            {
                path: "roles",
                name: "roles",
                component: () => import("@/views/roles/index.vue"),
                meta: {
                    hide: true,
                },
            },
            {
                path: "roles/create",
                name: "create-role",
                component: () => import("@/views/roles/create/index.vue"),
                props: true,
                meta: {
                    hide: true,
                },
            },
            {
                path: "roles/edit/:id",
                name: "edit-role",
                component: () => import("@/views/roles/edit/index.vue"),
                props: true,
                meta: {
                    hide: true,
                },
            },
            {
                path: "notification",
                name: "push-notification",
                component: () => import("@/views/push-notification/index.vue"),
                props: true,
                meta: {
                    hide: true,
                },
            },
            {
                path: "reports/create",
                name: "create-report",
                component: () => import("@/views/reports/create/index.vue"),
                props: true,
                meta: {
                    hide: true,
                },
            },
            {
                path: "report-view/:id",
                name: "report-view",
                component: () =>
                    import("@/views/reports/report-view/index.vue"),
                meta: {
                    hide: true,
                },
            },
            {
                path: "all-summary",
                name: "allSummary",
                component: () =>
                    import("@/views/analysis/all-summary/index.vue"),
                meta: {
                    hide: true,
                },
            },
            {
                path: "video-summary",
                name: "videoSummary",
                component: () =>
                    import("@/views/analysis/video-summary/index.vue"),
                meta: {
                    hide: true,
                },
            },
            {
                path: "video-summary/create-summary",
                name: "create-summary",
                component: () =>
                    import("@/views/analysis/video-summary/create/index.vue"),
                meta: {
                    hide: true,
                },
            },
            {
                path: "realtime-summary/create-summary",
                name: "create-realtime-summary",
                component: () =>
                    import(
                        "@/views/analysis/realtime-summary/create/index.vue"
                    ),
                meta: {
                    hide: true,
                },
            },
            {
                path: "video-story",
                name: "video-story",
                component: () =>
                    import("@/views/analysis/video-story/index.vue"),
                meta: {
                    hide: true,
                },
            },
            {
                path: "video-story-list",
                name: "video-story-list",
                component: () =>
                    import("@/views/analysis/video-story/list/index.vue"),
                meta: {
                    hide: true,
                },
            },
            {
                path: "report/details",
                name: "reportDetails",
                component: () => import("@/views/reports/details/index.vue"),
                meta: {
                    hide: true,
                },
            },
            {
                path: "report/details/output",
                name: "reportVideoClip",
                component: () =>
                    import("@/views/reports/Evidence Details/index.vue"),
                meta: {
                    hide: true,
                },
            },
            // {
            //   path: "video-summary/analysis-report/:id",
            //   name: "analysis-report",
            //   component: () => import("@/views/analysis/video-summary/report/index.vue"),
            //   meta: {
            //     hide: true,
            //   }
            // },
            // {
            //   path: "video-summary/:id",
            //   name: "videoSummaryDetails",
            //   component: () => import("@/views/analysis/video-summary/details/index.vue"),
            //   meta: {
            //     hide: true,
            //   }
            // },
            // {
            //     path: "video-summary/:id/video-clips",
            //     name: "videoSummaryClips",
            //     component: () =>
            //         import("@/views/reports/Evidence Details/index.vue"),
            //     props: true,
            //     meta: {
            //         hide: true,
            //     },
            // },
            // {
            //     path: "realtime-summary/:id",
            //     name: "realtimeVideoSummaryDetails",
            //     component: () =>
            //         import(
            //             "@/views/analysis/realtime-summary/details/index.vue"
            //         ),
            //     meta: {
            //         hide: true,
            //     },
            // },
            {
                path: "blank-page",
                name: "blank-page",
                component: () => import("@/views/blank-page.vue"),
            },
            {
                path: "billing",
                name: "billing",
                component: () => import("@/views/billing/index.vue"),
                meta: {
                    hide: true,
                },
            },
            {
                path: "profile",
                name: "profile",
                component: () => import("@/views/profile.vue"),
            },
            {
                path: "help",
                name: "help",
                component: () => import("@/views/help.vue"),
                meta: {
                    hide: true,
                },
            },
        ],
    },
    {
        path: "/:catchAll(.*)",
        name: "404",
        component: () => import("@/views/404.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    },
});

router.beforeEach((to, from, next) => {
    const accessToken = StorageUtil.getSessionData("accessToken");
    const titleText = to.name;
    const words = titleText.split(" ");
    const wordslength = words.length;
    for (let i = 0; i < wordslength; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }

    document.title = "Ro& - " + words;
    if (
        accessToken &&
        accessToken != "" &&
        accessToken != undefined &&
        accessToken != null &&
        to.name == "Login"
    ) {
        next({ name: "dashboard" }); // Proceed to dashboard
    } else {
        next();
    }
});

router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById("loading-bg");
    if (appLoading) {
        appLoading.style.display = "none";
    }
});

export default router;
