import dayjs from "dayjs";
import CryptoJS from "crypto-js";

// Function to encrypt data
export const encryptData = (data, secretKey) => {
    return CryptoJS.AES.encrypt(data, secretKey).toString();
};

// Function to decrypt data
export const decryptData = (cipherText, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
};

export function parseJwt(token) {
    let base64Url = token.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );
    // console.log("decode ur", jsonPayload.toString());
    return JSON.parse(jsonPayload);
}

//Currency to symbol
export function getCurrencySymbol(currencyCode) {
    const currencySymbols = {
        USD: "$", // US Dollar
        EUR: "€", // Euro
        GBP: "£", // British Pound
        JPY: "¥", // Japanese Yen
        AUD: "A$", // Australian Dollar
        CAD: "C$", // Canadian Dollar
        CHF: "CHF", // Swiss Franc
        CNY: "¥", // Chinese Yuan
        SEK: "kr", // Swedish Krona
        NZD: "NZ$", // New Zealand Dollar
        INR: "₹", // Indian Rupee
        RUB: "₽", // Russian Ruble
        BRL: "R$", // Brazilian Real
        ZAR: "R", // South African Rand
        MXN: "$", // Mexican Peso
        SGD: "S$", // Singapore Dollar
        HKD: "HK$", // Hong Kong Dollar
        NOK: "kr", // Norwegian Krone
        KRW: "₩", // South Korean Won
        TRY: "₺", // Turkish Lira
        PLN: "zł", // Polish Zloty
        PHP: "₱",
    };

    // Return the symbol if found, otherwise return the currency code itself
    return currencySymbols[currencyCode] || currencyCode;
}

//Seconds to time (1:5:00)
export function formatSecondsTime(sec) {
    const hours = Math.floor(sec / 3600); // Calculate the number of hours
    const minutes = Math.floor((sec % 3600) / 60); // Calculate the number of minutes
    const seconds = Math.round(sec % 60); // Calculate the number of seconds

    // Format the duration as "hh:mm:ss" or "mm:ss" if there are no hours
    const formattedDuration =
        hours > 0
            ? `${hours}:${minutes.toString().padStart(2, "0")}:${seconds
                  .toString()
                  .padStart(2, "0")}`
            : `${minutes}:${seconds.toString().padStart(2, "0")}`;

    return formattedDuration;
}

//ISO Date & time to Date (9th October 2024)
export const formatDate = (pubDate) => {
    const date = dayjs(pubDate);
    const day = date.format("D");
    const month = date.format("MMMM");
    const year = date.format("YYYY");

    const getOrdinalSuffix = (day) => {
        const dayNumber = parseInt(day, 10);
        if (dayNumber > 3 && dayNumber < 21) return "th";
        switch (dayNumber % 10) {
            case 1:
                return "st";
            case 2:
                return "nd";
            case 3:
                return "rd";
            default:
                return "th";
        }
    };

    const formattedDay = `${day}${getOrdinalSuffix(day)}`;

    return `${formattedDay} ${month} ${year}`;
};

export function millisecondsToHoursShort(milliseconds) {
    const hours = milliseconds / (1000 * 60 * 60);

    if (hours >= 1) {
        // If hours is a whole number, don't show decimal
        return `${hours % 1 === 0 ? Math.floor(hours) : hours.toFixed(1)} Hr`;
    }

    const minutes = milliseconds / (1000 * 60);

    if (minutes >= 1) {
        return `${Math.round(minutes)} Min`; // Show rounded minutes
    }

    const seconds = milliseconds / 1000;
    return `${Math.round(seconds)} Sec`; // Show rounded seconds
}

export function formatDateTime(dateString) {
    return dayjs(dateString).format("DD/MM/YYYY [at] HH:mm:ss");
}
//23 Oct 2014
export const formattedDate = (value) => {
    const date = new Date(value);
    const day = date.getUTCDate();
    const month = date.toLocaleString("en-us", { month: "short" });
    const year = date.getUTCFullYear();
    return `${day} ${month} ${year}`;
};

// Get specific address component by type (e.g., 'country')
const getComponent = (place, type) => {
    const component = place.address_components.find((comp) =>
        comp.types.includes(type)
    );
    return component ? component.long_name : null; // return long_name of the component
};

// Extract country from place details
export const extractCountry = (place) => {
    const countryComponent = getComponent(place, "country");
    return countryComponent;
};

const countryMapping = {
    Afghanistan: "AF",
    Albania: "AL",
    Algeria: "DZ",
    Andorra: "AD",
    Angola: "AO",
    "Antigua and Barbuda": "AG",
    Argentina: "AR",
    Armenia: "AM",
    Australia: "AU",
    Austria: "AT",
    Azerbaijan: "AZ",
    Bahamas: "BS",
    Bahrain: "BH",
    Bangladesh: "BD",
    Barbados: "BB",
    Belarus: "BY",
    Belgium: "BE",
    Belize: "BZ",
    Benin: "BJ",
    Bhutan: "BT",
    Bolivia: "BO",
    "Bosnia and Herzegovina": "BA",
    Botswana: "BW",
    Brazil: "BR",
    "Brunei Darussalam": "BN",
    Bulgaria: "BG",
    "Burkina Faso": "BF",
    Burundi: "BI",
    "Cabo Verde": "CV",
    Cambodia: "KH",
    Cameroon: "CM",
    Canada: "CA",
    "Central African Republic": "CF",
    Chad: "TD",
    Chile: "CL",
    China: "CN",
    Colombia: "CO",
    Comoros: "KM",
    Congo: "CG",
    "Congo (Democratic Republic of the)": "CD",
    "Costa Rica": "CR",
    Croatia: "HR",
    Cuba: "CU",
    Cyprus: "CY",
    Czechia: "CZ",
    Denmark: "DK",
    Djibouti: "DJ",
    Dominica: "DM",
    "Dominican Republic": "DO",
    Ecuador: "EC",
    Egypt: "EG",
    "El Salvador": "SV",
    "Equatorial Guinea": "GQ",
    Eritrea: "ER",
    Estonia: "EE",
    Eswatini: "SZ",
    Ethiopia: "ET",
    Fiji: "FJ",
    Finland: "FI",
    France: "FR",
    Gabon: "GA",
    Gambia: "GM",
    Georgia: "GE",
    Germany: "DE",
    Ghana: "GH",
    Greece: "GR",
    Grenada: "GD",
    Guatemala: "GT",
    Guinea: "GN",
    "Guinea-Bissau": "GW",
    Guyana: "GY",
    Haiti: "HT",
    Honduras: "HN",
    Hungary: "HU",
    Iceland: "IS",
    India: "IN",
    Indonesia: "ID",
    Iran: "IR",
    Iraq: "IQ",
    Ireland: "IE",
    Israel: "IL",
    Italy: "IT",
    Jamaica: "JM",
    Japan: "JP",
    Jordan: "JO",
    Kazakhstan: "KZ",
    Kenya: "KE",
    Kiribati: "KI",
    "Korea (Democratic People's Republic of)": "KP",
    "Korea (Republic of)": "KR",
    Kuwait: "KW",
    Kyrgyzstan: "KG",
    "Lao People's Democratic Republic": "LA",
    Latvia: "LV",
    Lebanon: "LB",
    Lesotho: "LS",
    Liberia: "LR",
    Libya: "LY",
    Liechtenstein: "LI",
    Lithuania: "LT",
    Luxembourg: "LU",
    Madagascar: "MG",
    Malawi: "MW",
    Malaysia: "MY",
    Maldives: "MV",
    Mali: "ML",
    Malta: "MT",
    "Marshall Islands": "MH",
    Mauritania: "MR",
    Mauritius: "MU",
    Mexico: "MX",
    "Micronesia (Federated States of)": "FM",
    "Moldova (Republic of)": "MD",
    Monaco: "MC",
    Mongolia: "MN",
    Montenegro: "ME",
    Morocco: "MA",
    Mozambique: "MZ",
    Myanmar: "MM",
    Namibia: "NA",
    Nauru: "NR",
    Nepal: "NP",
    Netherlands: "NL",
    "New Zealand": "NZ",
    Nicaragua: "NI",
    Niger: "NE",
    Nigeria: "NG",
    "North Macedonia": "MK",
    Norway: "NO",
    Oman: "OM",
    Pakistan: "PK",
    Palau: "PW",
    "Palestine, State of": "PS",
    Panama: "PA",
    "Papua New Guinea": "PG",
    Paraguay: "PY",
    Peru: "PE",
    Philippines: "PH",
    Poland: "PL",
    Portugal: "PT",
    Qatar: "QA",
    Romania: "RO",
    "Russian Federation": "RU",
    Rwanda: "RW",
    "Saint Kitts and Nevis": "KN",
    "Saint Lucia": "LC",
    "Saint Vincent and the Grenadines": "VC",
    Samoa: "WS",
    "San Marino": "SM",
    "Sao Tome and Principe": "ST",
    "Saudi Arabia": "SA",
    Senegal: "SN",
    Serbia: "RS",
    Seychelles: "SC",
    "Sierra Leone": "SL",
    Singapore: "SG",
    Slovakia: "SK",
    Slovenia: "SI",
    "Solomon Islands": "SB",
    Somalia: "SO",
    "South Africa": "ZA",
    "South Sudan": "SS",
    Spain: "ES",
    "Sri Lanka": "LK",
    Sudan: "SD",
    Suriname: "SR",
    Sweden: "SE",
    Switzerland: "CH",
    "Syrian Arab Republic": "SY",
    Tajikistan: "TJ",
    "Tanzania, United Republic of": "TZ",
    Thailand: "TH",
    "Timor-Leste": "TL",
    Togo: "TG",
    Tonga: "TO",
    "Trinidad and Tobago": "TT",
    Tunisia: "TN",
    Turkey: "TR",
    Turkmenistan: "TM",
    Tuvalu: "TV",
    Uganda: "UG",
    Ukraine: "UA",
    "United Arab Emirates": "AE",
    "United Kingdom": "GB",
    "United States": "US",
    Uruguay: "UY",
    Uzbekistan: "UZ",
    Vanuatu: "VU",
    "Venezuela (Bolivarian Republic of)": "VE",
    "Viet Nam": "VN",
    Yemen: "YE",
    Zambia: "ZM",
    Zimbabwe: "ZW",
};

export const getCountryCode = (countryName) => {
    return countryMapping[countryName] || null;
};

export const formatSize = (bytes) => {
    const k = 1024;
    const dm = 3;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export function formatDateReadable(dateString) {
    const date = new Date(dateString);

    // Get day, month, and year
    const day = String(date.getDate()).padStart(2, "0"); // Ensure two-digit day
    const month = date.toLocaleString("default", { month: "long" }); // Full month name
    const year = date.getFullYear();

    // Return formatted date
    return `${day} ${month}, ${year}`;
}
