import { RequestGateway } from "@/store/RequestGateway";
import StorageUtil from "@/utils/StorageUtil";
import { showToast } from "@/constant/toast";

const getDefaultState = () => ({
    configObjectList: [],
    videoList: [],
    storyList: [],
    storyListTotalRows: 0,
    mediaLibraryList: [],
    mediaLibraryTotalRows: 0,
    currentVideoUrl: "",
    storiesConsentApproval: StorageUtil.getLocalData("consent"),
});

export default {
    state: getDefaultState(),
    getters: {
        videoList: (state) => state.videoList,
        getConfigObjectList: (state) => state.configObjectList,
        getStoryList: (state) => state.storyList,
        getStoryListTotalRows: (state) => state.storyListTotalRows,
        getMediaLibraryList: (state) => state.mediaLibraryList,
        mediaLibraryTotalRows: (state) => state.mediaLibraryTotalRows,
        getCurrentVideoUrl: (state) => state.currentVideoUrl,
        getConsentApproval: (state) => state.storiesConsentApproval,
    },
    mutations: {
        CONFIG_OBJECT_LIST(state, data) {
            state.configObjectList = data;
        },
        VIDEO_LIST(state, data) {
            state.videoList = data;
        },
        GET_STORY_LIST(state, data) {
            state.storyList = data.storyData;
            state.storyListTotalRows = data.totalRow;
        },
        MEDIA_LIBRARY_LIST(state, data) {
            state.mediaLibraryList = data.mediaData;
            state.mediaLibraryTotalRows = data.totalRow;
        },
        CURRENT_VIDEO_URL(state, data) {
            state.currentVideoUrl = data;
        },
        STORIES_CONSENT_APPROVAL(state, data) {
            console.log("consent state", data);
            state.storiesConsentApproval = data;
            StorageUtil.setLocalData("consent", data);
        },
    },
    actions: {
        async uploadVideoAnalysis({ commit }, data) {
            const feature = "media";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/sessionsWithoutVideo`;

            try {
                const response = await RequestGateway.post(
                    feature,
                    url,
                    data,
                    {}
                );

                return response;
            } catch (error) {
                return error;
            }
        },
        async createVideoStory({ commit }, { payload, onProgress }) {
            const feature = "stories";
            const url = `/processMediaSSE`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
                data: payload,
                onDownloadProgress: function (progressEvent) {
                    const chunk = progressEvent.currentTarget.response;
                    // Call the callback function to handle the chunk in the component
                    if (onProgress) {
                        onProgress(chunk);
                    }
                },
            };

            try {
                const response = await RequestGateway.post(
                    feature,
                    url,
                    payload,
                    options
                );
                return response;
            } catch (error) {
                return error;
            }
        },
        async getVideoStories(
            { commit },
            { page = 1, perPage = 10, search = "", showLoading = false } = {}
        ) {
            if (showLoading) {
                commit("CHANGE_LOADING_STATUS", true);
            }

            const feature = "stories";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/getVideoStories?page=${page}&perPage=${perPage}&search=${encodeURIComponent(
                search
            )}`;

            try {
                const response = await RequestGateway.get(feature, url, {});

                commit("GET_STORY_LIST", {
                    storyData: response.data,
                    totalRow: response.totalCount,
                });
                return response.data;
            } catch (error) {
                commit("CHANGE_LOADING_STATUS", false);
                return error;
            } finally {
                if (showLoading) {
                    commit("CHANGE_LOADING_STATUS", false);
                }
            }
        },
        async videoStoriesConsentApproval({ commit }, payload) {
            const feature = "inhouse";
            const url = `/consent`;

            try {
                const response = await RequestGateway.post(
                    feature,
                    url,
                    payload,
                    {}
                );
                return response;
            } catch (error) {
                return error;
            }
        },
        async getConfigObject1({ commit }) {
            const feature = "media";
            const url = `/objects?perPage=200`;

            try {
                const response = await RequestGateway.get(feature, url, {});

                if (response.data) {
                    commit("CONFIG_OBJECT_LIST", response.data);
                    return response;
                } else {
                    commit("CONFIG_OBJECT_LIST", []);
                    return [];
                }
            } catch (error) {
                return error;
            }
        },
        async createSession({ commit }, payload) {
            const feature = "media";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/sessions`;

            try {
                const response = await RequestGateway.post(
                    feature,
                    url,
                    payload,
                    {}
                );
                if (response.status === 200) {
                } else {
                    if (response && response.data.error) {
                        let errorMsg =
                            response.data.error.charAt(0).toUpperCase() +
                            response.data.error.substring(1);
                    } else {
                    }
                }
                return response;
            } catch (error) {
                console.error(error);
                return error;
            }
        },
        async getMediaLibraryNew(
            { commit },
            { page = 1, perPage = 10, search = "", showLoading = false } = {}
        ) {
            if (showLoading) {
                commit("CHANGE_LOADING_STATUS", true);
            }

            const feature = "media";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/media?page=${page}&perPage=${perPage}&search=${encodeURIComponent(
                search
            )}&status=available`;

            try {
                const response = await RequestGateway.get(feature, url, {});
                if (response && response.data.length) {
                    commit("MEDIA_LIBRARY_LIST", {
                        mediaData: response.data,
                        totalRow: response.totalCount,
                    });
                } else {
                    commit("MEDIA_LIBRARY_LIST", {
                        data: [],
                        totalRow: 0,
                        currentPage: 1,
                        totalPages: 1,
                    });
                }

                return response.data;
            } catch (error) {
                commit("CHANGE_LOADING_STATUS", false);

                return error;
            } finally {
                if (showLoading) {
                    commit("CHANGE_LOADING_STATUS", false);
                }
            }
        },
        async getPresignedUrl({ commit }, { option, bucketName, mediaName }) {
            try {
                const response = await axios.get(
                    `https://media-bridge-dev.adminagcloud.workers.dev`,
                    {
                        params: {
                            fileName: mediaName,
                            bucketName,
                        },
                    }
                );
                commit("CURRENT_VIDEO_URL", response.url);
                return response.url;
            } catch (error) {
                console.error(error);
            }
        },
        async downloadMediaLibrary({ commit }, mediaLibraryId) {
            const feature = "media";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/media/${mediaLibraryId}/download`;

            try {
                const response = await RequestGateway.get(feature, url, {});
                commit("CURRENT_VIDEO_URL", response.data.url);
                return response;
            } catch (error) {
                return error;
            }
        },
        async saveMediaLibrary({ commit }, data) {
            const feature = "media";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/media`;
            const options = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
                onUploadProgress: (progressEvent) => {
                    data?.onUploadProgress &&
                        data.onUploadProgress(progressEvent);
                },
            };
            try {
                const response = await RequestGateway.post(
                    feature,
                    url,
                    data,
                    options
                );
                if (response.success === true) {
                    showToast(
                        "success",
                        "Success",
                        "Video Uploaded successfully"
                    );
                }
                return response;
            } catch (error) {
                return error;
            }
        },
        async downloadVideoSessionOutput({ commit }, data) {
            const feature = "media";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/sessions/${data.sessionId}/videos/${data.videoId}/outputs/${
                data.outputVideoId
            }/download`;

            try {
                const response = await RequestGateway.get(feature, url, {});
                return response;
            } catch (error) {
                return error;
            }
        },
        async deleteMediaLibraryVideo({ commit }, videoId) {
            const feature = "media";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/media/${videoId}`;

            try {
                const response = await RequestGateway.delete(feature, url, {});
                if (response.success === true) {
                    showToast(
                        "success",
                        "Success",
                        "Media library video deleted successfully"
                    );
                }
                return response;
            } catch (error) {
                return error;
            }
        },
    },
};
